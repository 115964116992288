<template>
  <div class="relative bg-gray-800">
    <div class="h-56 bg-primary-darker sm:absolute sm:left-0 sm:h-full sm:w-1/2">
      <img
        class="w-full h-full object-cover"
        src="/images/support_image.jpeg"
        alt=""
        role="presentation"
      >
    </div>
    <div class="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
      <div class="sm:ml-auto sm:w-1/2 sm:pl-10">
        <p class="text-base font-title font-semibold uppercase tracking-wider text-gray-300">
          We're here to help
        </p>
        <h2 class="mt-2 font-display support-contact text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
          Member Services
        </h2>
        <div class="mt-3 text-lg text-gray-300">
          <div v-if="hoursLoading">
            <i class="fas fa-spinner animate-spin" />
          </div>
          <div v-if="hours" id="agency-hours">
            <div v-for="message in hours.data" :key="message.id">
              <p style="white-space: pre-line">
                {{ message.message }}
              </p>
            </div>
          </div>
        </div>
        <p v-if="error" class="mt-3 text-lg text-gray-300">
          GradGuard team members are ready to assist you
          from 10am to 7pm EST, Monday through Friday.
        </p>
        <div class="mt-8">
          <div class="lg:inline-flex space-x-0 lg:space-x-8 space-y-4 lg:space-y-0 rounded-md">
            <div>
              <a
                href="tel:8669857598"
                class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-renters-darker bg-green-100 hover:bg-gray-50"
              >
                Call (866) 985-7598
                <svg
                  class="-mr-1 ml-3 h-5 w-5 text-gray-800"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { defineComponent } from 'vue';

import useAgencyHours from './useAgencyHours';

export default defineComponent({
  name: 'AgencyHours',
  setup() {
    const defaultDate = dayjs().format('YYYY-MM-DD');
    const params = new URLSearchParams(window.location.search);
    const date = params.get('preview-date') ?? defaultDate;
    const { data: hours, isFetching: hoursLoading, error } = useAgencyHours({ date });

    return {
      hours,
      hoursLoading,
      error,
    };
  },
});
</script>
